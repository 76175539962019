/* Primary */
$primary: var(--primary);
$on-primary: var(--on-primary);

/* Secondary */
$secondary: var(--secondary);
$on-secondary: var(--on-secondary);

/* Background */
$background: var(--background);
$on-background: var(--on-background);

/* Navbar */
$navbar: var(--navbar);
$on-navbar: var(--on-navbar);
$navbar-shadow: var(--navbar-shadow);

/* Banner */
$banner: var(--banner);
$on-banner: var(--on-banner);

/* Footer */
$on-footer: var(--on-footer);

/* Badge */
$badge: var(--badge);
$on-badge: var(--on-badge);

/* Icon */
$icon: var(--icon);
$on-icon: var(--on-icon);
$icon-hover: var(--icon-hover);
$on-icon-hover: var(--on-icon-hover);
$on-icon-inner: var(--on-icon-inner);

/* Applications */
$github: var(--github);
$linkedin: var(--linkedin);
$pdf: var(--pdf);
$theme-button: var(--theme-button);

/* Skills */
// Java
$on-java: var(--on-java);
$java: var(--java);
// C++
$on-cplusplus: var(--on-cplusplus);
$cplusplus: var(--cplusplus);
// C#
$on-csharp: var(--on-csharp);
$csharp: var(--csharp);
// Spring Framework
$on-spring: var(--on-spring);
$spring: var(--spring);
// Angular
$on-angular: var(--on-angular);
$angular: var(--angular);
// Python
$on-python: var(--on-python);
$python: var(--python);
// Android
$on-android: var(--on-android);
$android: var(--android);
// HTML
$on-html: var(--on-html);
$html: var(--html);
// CSS
$on-css: var(--on-css);
$css: var(--css);
// JavaScript
$on-javascript: var(--on-javascript);
$javascript: var(--javascript);
// TypeScript
$on-typescript: var(--on-typescript);
$typescript: var(--typescript);
// Keycloak
$on-keycloak: var(--on-keycloak);
$keycloak: var(--keycloak);
// MySQL
$on-mysql: var(--on-mysql);
$mysql: var(--mysql);
// MongoDB
$on-mongodb: var(--on-mongodb);
$mongodb: var(--mongodb);
// Gitlab
$on-gitlab: var(--on-gitlab);
$gitlab: var(--gitlab);
//Docker
$on-docker: var(--on-docker);
$docker: var(--docker);
// Kubernetes
$on-kubernetes: var(--on-kubernetes);
$kubernetes: var(--kubernetes);
