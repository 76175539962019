/* Fonts */
$font-family: Gudea, 'Helvetica Neue', sans-serif;

/* Text */
$title-delimiter: '>';

/* Delays */
$transition-delay: 0.3s;

/* Widths */
$tablet-width: 1023px;
$phone-width: 767px;
