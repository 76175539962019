@use '../../assets/styles/variables/variables';

.App {
  margin: 0;
  padding: 0 10% 5rem;

  .columns {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;

    .column {
      width: calc(50vw - 15%);
    }
  }

  .Footer {
    display: none;
  }
}

@media (max-width: variables.$tablet-width) {
  .App {
    padding: 0 5% 5rem;

    .columns {
      .column {
        width: 100%;
      }
    }

    .Footer {
      display: block;
    }
  }
}

@media (max-width: variables.$phone-width) {
  .App {
    padding: 0 2% 5rem;
  }
}
