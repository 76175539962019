@use '../../assets/styles/variables/theme-variables';

/* Based on: https://cssloaders.github.io/ */
.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 2rem auto;
  position: relative;
  color: theme-variables.$primary;
  box-sizing: border-box;
  animation: animloader 2s linear infinite alternate;
}

@keyframes animloader {
  0% {
    box-shadow:
      -38px -6px,
      -14px 6px,
      14px -6px;
  }
  33% {
    box-shadow:
      -38px 6px,
      -14px -6px,
      14px 6px;
  }
  66% {
    box-shadow:
      -38px -6px,
      -14px 6px,
      14px -6px;
  }
  100% {
    box-shadow:
      -38px 6px,
      -14px -6px,
      14px 6px;
  }
}
