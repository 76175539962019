@use '../../assets/styles/variables/theme-variables';

.skillTitle {
  text-align: center;
  font-variant: small-caps;
  color: theme-variables.$on-background;
}

.rowContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
