@font-face {
  font-family: Gudea;
  src: url('../../fonts/Gudea-Regular.ttf');
}

@font-face {
  font-family: Gudea;
  src: url('../../fonts/Gudea-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Gudea;
  src: url('../../fonts/Gudea-Italic.ttf');
  font-style: italic;
}
