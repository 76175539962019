.faIcon {
  font-size: 22pt;
  user-select: none;
}

.mongodb {
  transform: rotate(45deg);
}

.spring {
  font-size: 20pt;
}
